.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 72px;
  padding: 0 16px;
  box-sizing: border-box;
  border-bottom: 2px solid black;
  background-color: #fcf9f6;

  > .title {
    width: 75px;
    height: 20.5px;
  }
  > .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    > .caret,
    > .add {
      width: 24px;
      height: 24px;
    }
  }
}
