.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  transition: background-color 0.5s;

  > .top {
    flex: 1;
    display: flex;
    position: relative;

    > .store {
      position: absolute;
      top: 16px;
      left: 16px;
      width: 70px;
      height: 26px;
    }
    > .next {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 39px;
      height: 26px;
    }
    > .btn {
      position: absolute;
      bottom: 16px;
      right: 16px;
      width: 81px;
      height: 38px;
    }
    > .sliders {
      flex: 1;
      display: flex;
      flex-direction: row;
      overflow-x: hidden;
      pointer-events: none;

      > .slider {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 100vw;

        > img {
          width: 100%;
          max-width: 292px;
        }
        > .shadow {
          margin-top: -16px;
          width: 160px;
          height: 24px;
          background-color: black;
          border-radius: 50%;
          opacity: 0.4;
          filter: blur(12px);
        }
      }
    }
  }
  > .bottom {
    height: 80px;
    position: relative;
    overflow: hidden;

    > img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: auto;
      animation: scrollUpDown 10s linear infinite alternate;
    }
  }
}

@keyframes scrollUpDown {
  0% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, calc(-100% + 80px));
  }
}
