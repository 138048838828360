.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 72px;
  padding: 0 16px;
  box-sizing: border-box;
  border-bottom: 2px solid black;
  background-color: white;

  > .title {
    width: 57px;
    height: 19px;
  }
  > .arrow {
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
  }
}
