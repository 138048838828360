.container {
  position: relative;
  border-bottom: 2px solid black;
  background-color: #5bb784;
  overflow: hidden;

  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    padding: 0 16px;

    > .title {
      width: 67.5px;
      height: 23.5px;
    }
    > .arrow {
      width: 24px;
      height: 24px;
      transform: rotate(180deg);
      transition: all 0.5s;

      &.close {
        transform: rotate(1deg);
      }
    }
  }
  > .body {
    max-height: 0;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;

    &.open {
      max-height: 400px;
    }
    > .board {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 4px;
      position: absolute;
      top: 30px;
      right: 40px;
      left: 40px;

      > .stamp {
        width: 70px;
        height: 70px;
        transform: scale(0);

        &.stamped {
          transform: scale(1);
          transition: transform 0.5s ease;
        }
        &.hide {
          visibility: hidden;
        }
      }
    }
  }
}
