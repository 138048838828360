.container {
  position: relative;
  border-bottom: 2px solid black;
  background-color: #fad7e6;
  overflow: hidden;

  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    padding: 0 16px;

    > .title {
      width: 78.5px;
      height: 23.5px;
    }
    > .arrow {
      width: 24px;
      height: 24px;
      transform: rotate(180deg);
      transition: all 0.5s;

      &.close {
        transform: rotate(1deg);
      }
    }
  }
  > .body {
    max-height: 0;
    transition: all 0.5s;

    &.open {
      max-height: 400px;
    }
    .coupon {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06), 0 -4px 8px rgba(0, 0, 0, 0.03);
    }
  }
}

.swiper {
  width: 100%;
  height: 160px;
}
.swiperSlide {
  background-position: center;
  background-size: cover;
  width: 303px;
  height: 132.5px;
}
