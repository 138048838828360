.container {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;

  > .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-image: url('./news-bg.png');
    animation: bgWave 5s ease-in-out infinite;
    transform-origin: center;
  }
  > .news {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 48px;
    height: 26px;
  }
  > .illust {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    animation: rotateSwing 1.5s ease-in-out infinite;
    transform-origin: center center;
  }
  > .page {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 16px;
    left: 50%;
    width: 43px;
    height: 23px;
    transform: translateX(-50%);
    background-color: rgba(black, 0.4);
    width: 44px;
    height: 24px;
    font-size: 12px;
    padding-bottom: 2px;
    box-sizing: border-box;
    color: white;
    border-radius: 12px;

    > span {
      color: rgba(white, 0.4);
    }
  }
}

.swiper {
  width: 307px;
  height: 418px;
}
.swiperSlide {
  border-radius: 16px;
}

@keyframes bgWave {
  0% {
    background-position: 50% 50%;
    transform: scale(1) rotate(0deg);
  }
  25% {
    background-position: 53% 47%;
    transform: scale(1.03) rotate(-0.5deg);
  }
  50% {
    background-position: 50% 53%;
    transform: scale(1.015) rotate(0.5deg);
  }
  75% {
    background-position: 47% 47%;
    transform: scale(1.03) rotate(-0.3deg);
  }
  100% {
    background-position: 50% 50%;
    transform: scale(1) rotate(0deg);
  }
}

@keyframes rotateSwing {
  0% {
    transform: scaleX(-1) rotate(0deg);
  }
  15% {
    transform: scaleX(-1) rotate(30deg);
  }
  30% {
    transform: scaleX(-1) rotate(0deg);
  }
  45% {
    transform: scaleX(-1) rotate(30deg);
  }
  60% {
    transform: scaleX(-1) rotate(0deg);
  }
  100% {
    transform: scaleX(-1) rotate(0deg);
  }
}
