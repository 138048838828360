img {
  width: 100%;
  vertical-align: middle;
  margin: -0.5px 0;
}

.container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;

  > .background_01 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    background-image: url('../../assets/images/lotteworld/02-1.jpg');
    background-size: contain;

    > img {
      width: calc(590px / 2);
    }
    > .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: calc(590px / 2 - 16px);

      > .label {
        flex: 1;
        font-size: 20px;
        font-weight: bold;
        color: #1a0a6c;
      }
      > .value {
        font-size: 20px;
        font-weight: bold;
        color: #7e1bf2;
      }
    }
  }
  > .background_02 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../assets/images/lotteworld/05.jpg');
    background-size: contain;

    > img {
      width: calc(590px / 2);
    }
  }
  > .layer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(black, 0.5);

    > .popup {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: 320px;
      background-color: white;
      border-radius: 16px;
      overflow: hidden;
      transform: translate(-50%, -50%);

      > .close {
        position: absolute;
        top: 4px;
        right: 4px;
        width: 40px;
        height: 40px;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 30px;
          height: 2px;
          background-color: black;
          transform-origin: center;
          transition: background-color 0.3s ease, transform 0.3s ease;
        }
        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
      > .grid {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 24px;

        > .cell {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          width: 40%;

          > .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            &.selected {
              > .circle {
                border-color: #db1111;
                background-color: #db1111;
              }
              > .text {
                color: #db1111;
              }
            }
            > .circle {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              border: 1px solid gray;

              &.selected {
                border-color: #db1111;
                background-color: #db1111;
              }
            }
            > .text {
              font-size: 18px;
              font-weight: bold;
              line-height: 1;
            }
          }
        }
      }
      > .fieldWrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;

        > .field {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          padding: 8px 8px 8px 12px;
          background-color: #eeeeee;
          border-radius: 8px;
          box-sizing: border-box;

          > .code {
            flex: 1;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
          }
        }
      }
      > .history {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0 16px;
        margin-bottom: 24px;

        > .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          > .code {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            font-size: 16px;
            font-weight: bold;
            border-radius: 8px;
            background-color: #eeeeee;
          }
          > .button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            padding: 0 12px;
            color: white;
            font-size: 16px;
            font-weight: bold;
            border-radius: 8px;
            background-color: #333333;
          }
        }
      }
      > .buttonWrapper {
        padding: 0 20px;
      }
    }
  }
}
