.container {
  position: relative;
  border-bottom: 2px solid black;
  background-color: #ffec90;
  overflow: hidden;

  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    padding: 0 16px;

    > .title {
      width: 48px;
      height: 19px;
    }
    > .arrow {
      width: 24px;
      height: 24px;
      transform: rotate(180deg);
      transition: all 0.5s;

      &.close {
        transform: rotate(1deg);
      }
    }
  }
  > .body {
    max-height: 0;
    transition: all 0.5s;

    &.open {
      max-height: 400px;
    }
    > .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      padding-bottom: 16px;
      position: relative;

      > .amount {
        position: absolute;
        right: 40px;
        top: 92px;
        font-size: 28px;
        font-weight: bold;
      }
      > .buttons {
        display: flex;
        align-items: center;
        gap: 8px;

        > img {
          width: 81px;
          height: 38px;
        }
      }
    }
  }
}
